import React from "react";
import { useMedia } from "use-media";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import TopFormWithImage from "../../components/TopFormCenterImage";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";
import {
  autoDesigner,
  chatChannel,
  logs,
  marketplace,
  dialog,
  onprem_icon,
  c_ai,
} from "./chatbot-builder";

const TopImage = require("../../assets/img/feature/automation_designer/chatbot_automation_header.png");
const TopImage_mob = require("../../assets/img/feature/automation_designer/chatbot_automation_header.png");

export const icon1 = require("../../assets/img/feature/sec_1_bg.png");
export const icon2 = require("../../assets/img/feature/sec_2_bg.png");
export const icon3 = require("../../assets/img/feature/sec_3_bg.png");
export const icon4 = require("../../assets/img/feature/sec_4_bg.png");

export const cardOne = require("../../assets/images/homepage/group1.png");
export const cardTwo = require("../../assets/images/homepage/group4.png");
const section1 = require("../../assets/img/feature/automation_designer/chatbot_automation_sec_1.png");
const section2 = require("../../assets/img/feature/automation_designer/chatbot_automation_sec_2.png");
const section3 = require("../../assets/img/feature/automation_designer/chatbot_automation_sec_3.png");
const section4 = require("../../assets/img/feature/automation_designer/chatbot_automation_sec_4.png");
const section5 = require("../../assets/img/feature/automation_designer/chatbot_automation_sec_5.png");
const section6 = require("../../assets/img/feature/automation_designer/chatbot_automation_sec_6.png");
const section7 = require("../../assets/img/feature/automation_designer/chatbot_automation_sec_7.png");
const section8 = require("../../assets/img/feature/automation_designer/chatbot_automation_sec_8.png");
const section9 = require("../../assets/img/feature/automation_designer/chatbot_automation_sec_9.png");
const section10 = require("../../assets/img/feature/automation_designer/chatbot_automation_sec_10.png");
const section11 = require("../../assets/img/feature/automation_designer/chatbot_automation_sec_11.png");

export const chabotApproval = require("../../assets/img/feature/agent_handover/all_feature_approvals_icon.png");
export const faqBuilder = require("../../assets/img/feature/agent_handover/all_feature_faq_icon.png");
export const liveAgent = require("../../assets/img/feature/agent_handover/all_feature_agent_handover_icon.png");
const knowledgeAi = require("../../assets/img/feature/knowledge_ai/knowledgeai_icon.svg");
export const otherFeatures = [
  {
    image: dialog,
    alt: "Chatbot Builder​​",
    header: "Chatbot Builder​​",
    content:
      "Use no-code visual canvas to create engaging conversations for your chatbot easily in minutes.",
    link: "/conversational-ai-platform/chatbot-builder",
  },
  {
    image: c_ai,
    alt: "Conversational AI Platform",
    header: "Conversational AI Platform",
    content:
      "Learn more on workativ assistants’ conversational ai platform capabilities.",
    link: "/conversational-ai-platform/conversational-ai",
  },
  {
    image: faqBuilder,
    alt: "FAQ Bot Builder",
    header: "FAQ Bot Builder",
    content:
      "Simple and easy to understand UI to help you automate your repetitive FAQs easily in minutes.",
    link: "/conversational-ai-platform/faq-chatbot-builder",
  },
  {
    image: knowledgeAi,
    alt: "Knowledge AI",
    header: "Knowledge AI​",
    new: true,
    content:
      "Knowledge AI leverages the power of LLMs and Generative AI to provide contextually relevant responses to user queries.",
    link: "/conversational-ai-platform/knowledge-ai",
  },
  {
    image: marketplace,
    alt: "Chatbot  Marketplace",
    header: "Chatbot  Marketplace",
    content:
      "Download pre-built chatbots, 100+ app integrations, and 1000+ app workflows in seconds and go live instantly.",
    link: "/conversational-ai-platform/app-workflow-template-marketplace",
  },
  {
    image: chatChannel,
    alt: "Omnichannel",
    header: "Omnichannel",
    content:
      "Deploy chatbot on your favorite collaboration apps like Teams, Slack, or as Chat Widget easily in few clicks.",
    link: "/conversational-ai-platform/chatbot-channels",
  },
  {
    image: liveAgent,
    alt: "Live Agent Handover",
    header: "Live Agent Handover",
    content:
      "Seamless ‘agent handover’ of chatbot to live agents with complete user context and conversation history.",
    link: "/conversational-ai-platform/agent-handover-chatbot",
  },

  {
    image: chabotApproval,
    alt: "Chatbot Approval",
    header: "Chatbot Approval​",
    content:
      "Approving chatbot tasks made easy with our approval management. Set approval reminders, expiry, tracking, and more.",
    link: "/conversational-ai-platform/approval-management-chatbot",
  },
  {
    image: logs,
    alt: "Chatbot Analytics & Performance",
    header: "Chatbot Analytics & Performance",
    content:
      "Gather insight to craft better end user experiences and deliver better chatbot support to your employees.",
    link: "/conversational-ai-platform/chatbot-analytics-performance",
  },
];

const featureContent = [
  {
    header: "Build app workflows for chatbot",
    content:
      "Our no-code workflow builder helps you build simple single step or complex multistep app workflows for chatbot easily. Triger app automations from chatbot, execute parallel actions, or combine data using join capabilities – we got you covered. Adding app automation to chatbot made simple and easy for just about anybody to get going in minutes.",
    image: section1,
    position: "right",
    alt: "app workflows for chatbot",
  },

  {
    header: "App integrations for chatbot",
    content:
      "Workativ provides pre-built app integrations so you don’t have to custom develop. Connect chatbot with your apps securely in few clicks as workativ supports industry leading authentications such as OAuth, Basic Auth, and Custom Auth to support various app authentication needs.",
    image: section2,
    alt: "App integration",
  },
  {
    header: "Trigger alerts to users from app",
    content:
      "Send alerts to users from bot to take action at workplace. Be it alerting on outage information, policy changes, updating their passwords, filling surveys, or simply surprise them on their birthdays etc. Never let your employees miss a beat. ",
    content2:
      "Alerts can be setup using app triggers based on conditions. e.g., password expiry, employee onboarding, system outage etc.",
    image: section3,
    position: "right",
    alt: "Trigger alerts",
  },
  {
    header: "App workflows for chatbot",
    content:
      "Connect your chatbot with over 100+ apps and 1000+ app workflows so you can automate requests and auto resolve issues instantly from chatbot. Easily download, connect, and activate in few clicks. No coding.",
    image: section4,
    link: "Go to workflow marketplace",
    url: "/conversational-ai-platform/app-workflow-template-marketplace",
    alt: "App workflow",
  },
  {
    header: "Approve your chatbot workflows in seconds",
    image: section5,
    content:
      "Automating workplace requests can sometimes requires manager or special approval. Workativ helps you automate and manage approvals for employee requests from chatbot easily out-of-the box with approval management capability. Setting up approvals, notification, reminders, expiry, and tracking made simple for you.",
    alt: "Approve your chatbot workflows in seconds",
    position: "right",
  },
  {
    header: "On-prem app connector",
    content:
      "On-prem Connect is a secure connector to help you connect chatbot with your on-prem applications behind your firewall seamlessly and securely, so you can create and execute app workflows from chatbot be it on-prem or cloud apps easily. Installing on-prem connector and connecting apps is easy and no coding required at all.",
    image: section6,
    link: "More on on-prem connect",
    url: "/conversational-ai-platform/on-prem-app-connector/",
    alt: "on prem app connector",
  },
  {
    header: "Workflow process orchestration",
    image: section7,
    content:
      "Workativ uses powerful workflow process orchestration engine to orchestrate multiple tasks from chatbot through multiple apps. Using chatbot, you can seamlessly automate tasks, process, and workflows spanning several apps.",
    alt: "Workflow process orchestration",
    position: "right",
  },
  {
    header: "Error handling",
    content:
      "Workativ’s advanced error handling will help you handle errors that may occur when chatbot executes workflows. And you may set conditions, and or notifications that will help take next best workflow action to either overcome and move forward or report to the admin.",
    image: section8,
    alt: "error handling",
  },
  {
    header: "Workflow testing",
    content:
      "Testing is critical to workflows to ensure they work at each step. Using workativ’s testing capability you can test workflows with live data at every step of the workflow to ensure your workflows are in order.",
    image: section9,
    alt: "workflow testing",
    position: "right",
  },
  {
    header: "Analyze app workflows",
    content:
      "Analytics help you identify the number of app workflows triggered by chatbot. Track most or least used workflow for a given period, helping you fine-tune workflows as well.",
    image: section10,
    alt: "Analyze app workflows",
  },
  {
    header: "App workflow logs",
    content:
      "Use logs to see the entire app workflow execution path from chatbot and drill down on specifics for more in-depth analysis. Know what went wrong if the workflow failed to execute, review input data for workflow and use log data to fix the issue.",
    image: section11,
    alt: "App workflow logs",
    position: "right",
  },
];

export const cardData = [
  {
    image: cardOne,
    header: "Workativ for Conversational IT Support",
    content: `Deliver faster and superior IT Support, powered by Conversational AI and Automation. Augment your IT Help Desk to scale your support easily in a few clicks. `,
    content_1: `No coding required.`,
    linkAddress: "/assistant/it-helpdesk-chatbot",
    backgroundClass: "bg_card_1",
  },
  {
    image: cardTwo,
    header: "Workativ for Conversational HR Support",
    content: `Transform HR experience for employees with our AI-powered chatbots with prebuilt HR process automation. Free up your HR staff to focus on high value work.`,
    content_1: ` No coding required.`,
    linkAddress: "/conversational-ai-platform/conversational-hr-support",
    backgroundClass: "bg_card_2",
  },
];

export default function AutomationDesigner() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Free Chatbot Automation | Workativ Assistant Chatbot"
        description="Integrate your apps with the chatbot, add workflow automation, create or use pre-built workflows, and connect with on-prem apps easily in minutes. "
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={TopImage}
        ogTitle="Free Chatbot Automation | Workativ Assistant Chatbot"
        ogDescription="Integrate your apps with the chatbot, add workflow automation, create or use pre-built workflows, and connect with on-prem apps easily in minutes. "
      />
      <Container additionalClass={"feature-display-none"}>
        <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
          <TopFormWithImage
            image={TopImage}
            altImage={"Chatbot Automation Builder"}
            image_mob={TopImage_mob}
            additionalClassImage={"width-100"}
            additionalClassSection={"features_indvidual_dec"}
          >
            <TopFormWithImage.Header>
              Chatbot Automation
            </TopFormWithImage.Header>
            <TopFormWithImage.Content>
              Workativ assistant comes with easy-to-use no-code workflow builder
              to help you build simple to complex app-based automations for
              chatbot in minutes. Integrate chatbot with apps, use pre-built
              workflows from marketplace, approve requests, connect chatbot with
              on-prem apps and much more.
            </TopFormWithImage.Content>
            <RequestForm isFooterForm={false} />
          </TopFormWithImage>
          {/* {isSmall ? null : <OnScrollPopup />} */}

          {featureContent.map((feature, index) => {
            if (feature.position === "right") {
              return (
                <RightImageWithContentFeature
                  image={feature.image}
                  altImage={feature.alt}
                  additionalClass={"feature-padding-change"}
                >
                  <RightImageWithContentFeature.Header>
                    <h3 className="font-section-header">{feature.header}</h3>
                  </RightImageWithContentFeature.Header>
                  <RightImageWithContentFeature.SubHeader>
                    {feature.link && feature.content2 && feature.content ? (
                      <>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          {feature.content}
                        </p>
                        <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                          {feature.content2}
                        </p>
                      </>
                    ) : feature.link && feature.content ? (
                      <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                        {feature.content}
                      </p>
                    ) : feature.content && feature.content2 ? (
                      <>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          {feature.content}
                        </p>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          {feature.content2}
                        </p>
                      </>
                    ) : feature.content ? (
                      <p className="font-section-normal-text-testimonials line-height-18">
                        {feature.content}
                      </p>
                    ) : null}
                  </RightImageWithContentFeature.SubHeader>
                  {feature.link && feature.url && (
                    <div className="card_link_landing">
                      <a
                        href={feature.url}
                        className="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                      >
                        {feature.link}&nbsp; &#8594;
                      </a>
                    </div>
                  )}
                </RightImageWithContentFeature>
              );
            }
            return (
              <div className="features_container_left">
                <LeftImageWithContent
                  image={feature.image}
                  altImage={feature.alt}
                >
                  <LeftImageWithContent.HeaderIcon>
                    <h3 className="font-section-header">{feature.header}</h3>
                  </LeftImageWithContent.HeaderIcon>

                  <LeftImageWithContent.SubHeader>
                    {feature.link && feature.content2 && feature.content ? (
                      <>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          {feature.content}
                        </p>
                        <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                          {feature.content2}
                        </p>
                      </>
                    ) : feature.link && feature.content ? (
                      <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                        {feature.content}
                      </p>
                    ) : feature.content && feature.content2 ? (
                      <>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          {feature.content}
                        </p>
                        <p className="font-section-normal-text-testimonials line-height-18">
                          {feature.content2}
                        </p>
                      </>
                    ) : feature.content ? (
                      <p className="font-section-normal-text-testimonials line-height-18">
                        {feature.content}
                      </p>
                    ) : null}
                  </LeftImageWithContent.SubHeader>
                  {feature.link && feature.url && (
                    <div className="card_link_landing">
                      <a
                        href={feature.url}
                        className="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                      >
                        {feature.link}&nbsp; &#8594;
                      </a>
                    </div>
                  )}
                </LeftImageWithContent>
              </div>
            );
          })}
          <section className="cards_features bg_fff">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-12 col-sm-12 cards_features_header">
                  <div className="build_needs">
                    <div className="col-lg-12 col-md-12 col-12 p-0 center_features_header">
                      <h2 className="font-section-header">
                        Explore More Features
                      </h2>
                      <p className="font-section-normal-text-testimonials line-height-18">
                        Our 3-in-1 support automation platform combines the
                        power of Gen AI chatbot for automating chats, App
                        workflow automation for auto-resolution of issues, and
                        Shared Live Inbox for seamless live chat interactions.
                        And more…!
                      </p>
                    </div>
                  </div>
                  <section className="whitepaper_cards">
                    <div className="container-fluid p-0 c_a">
                      <div className="row">
                        <ul className="cards">
                          {otherFeatures.map((data) => (
                            <li className="cards__item">
                              <div className="card">
                                <div className="card__image">
                                  <img
                                    loading="lazy"
                                    src={data.image}
                                    alt={data.alt}
                                  ></img>
                                </div>
                                <div className="card__content">
                                  <div className="card__title font-section-normal-text-medium">
                                    {data.header}
                                    {data.beta ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        Beta
                                      </span>
                                    ) : null}
                                    {data.new ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        New
                                      </span>
                                    ) : null}
                                  </div>
                                  <p className="card__text font-section-small-text pb-0">
                                    {data.content}
                                  </p>
                                  <div class="card_link_landing">
                                    <a
                                      class="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                                      href={data.link}
                                    >
                                      Know more&nbsp; &#8594;
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
          {/* <GoToPopup />
          <CookiesPoup /> */}
          <div className="isfooter_form">
            <RequestForm isFooterForm={true} />
          </div>
        </Layout>
      </Container>
    </>
  );
}
